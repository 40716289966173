import React from "react"
import { StaticQuery, graphql } from "gatsby"

import "./resume.css"

const Resume = ({ data }) => {
  const style = {
    borderTop: "3px solid " + data.color,
  }
  return (
    <div className='link-block' id={data.navid} style={style}>
      <div className='icon'>
        <img src={data.logo.childImageSharp.fixed.src} alt='data.text' />
      </div>
      <div className='link-items'>
        <a href={data.link}>{data.text}</a>
      </div>
    </div>
  )
}

const Resumes = () => (
  <StaticQuery
    query={graphql`
      query {
        allLinksJson {
          nodes {
            text
            link
            logo {
              id
              childImageSharp {
                fixed(width: 22) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div id='resume' className='area'>
        <h2>Resumes</h2>
        <div className='link'>
          {data.allLinksJson.nodes.map((node, id) => (
            <div key={id}>
              <Resume data={node} />
            </div>
          ))}
        </div>
      </div>
    )}
  />
)

export default Resumes
