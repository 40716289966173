import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"
import Educations from "../components/education"
import Resumes from "../components/resume"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title='Home' />
    <h1 style={{ textTransform: `uppercase` }}>Resume</h1>
    <Resumes />
    <Works />
    <Educations />
  </Layout>
)

export default IndexPage
