import React from "react"
import { StaticQuery, graphql } from "gatsby"

import "./education.css"

const Education = ({ data }) => {
  const style = {
    borderTop: "3px solid " + data.color,
  }
  return (
    <div className="edu-block" id={data.navid} style={style}>
      <h3>{data.school}</h3>
      <p>
        <strong>{data.degree}</strong> --- {data.major}
      </p>
      <p>
        {data.start}-{data.end}
      </p>
    </div>
  )
}

const Educations = () => (
  <StaticQuery
    query={graphql`
      query {
        allEducationJson {
          nodes {
            school
            end
            start
            degree
            major
          }
        }
      }
    `}
    render={data => (
      <div id="education" className="area">
        <h2>Education</h2>
        <div className="edu">
          {data.allEducationJson.nodes.map((node, id) => (
            <div key={id}>
              <Education data={node} />
            </div>
          ))}
        </div>
      </div>
    )}
  />
)

export default Educations
