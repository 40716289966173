import React from "react"
import { StaticQuery, graphql } from "gatsby"

import "./works.css"

const Work = ({ data }) => {
  const style = {
    borderTop: "3px solid " + data.color,
  }
  return (
    <div className="work-block" id={data.navid} style={style}>
      <div className="work-card">
        <img src={data.logo.childImageSharp.fixed.src} alt="data.company" />
        <h3>{data.title}</h3>
        <p>
          <strong>{data.company}</strong>
        </p>
        <p>
          {data.start}-{data.end}
        </p>
        <p>{data.location}</p>
      </div>
      <div className="work-items">
        <ul>
          {data.responsibilities.map((res, id) => (
            <li key={id}>
              <p>{res}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const Works = () => (
  <StaticQuery
    query={graphql`
      query {
        allWorkJson {
          nodes {
            company
            navid
            end
            location
            responsibilities
            start
            title
            color
            logo {
              id
              childImageSharp {
                fixed(width: 200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div id="work" className="area">
        <h2>Work Expriences</h2>
        {data.allWorkJson.nodes.map((node, id) => (
          <div key={id}>
            <Work data={node} />
          </div>
        ))}
      </div>
    )}
  />
)

export default Works
